import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Category } from '../models/category.model';
import { Question } from '../models/question.model';
import {
  BulkInsertGoldenPointsBody,
  BulkInsertQuestionBody,
} from './bulk-insert.interface';
import {
  AddCategoryBody,
  UpdateCategoryBody,
} from './category-gateway.interface';
import {
  PaginatedResult,
  RawCategory,
  RawQuestion,
  Result,
} from './common.interface';
import { toQuestion } from './question.factory';

@Injectable({ providedIn: 'root' })
export class CategoryGateway {
  private readonly BASE_URL = `${environment.baseUrl}/admin/categories`;
  private readonly QUESTIONS_BASE_URL = `${environment.baseUrl}/admin/question_category`;

  constructor(private readonly http: HttpClient) {}

  category(id: number): Observable<Result<Category>> {
    return this.http.get<Result<RawCategory>>(`${this.BASE_URL}/${id}`).pipe(
      map((result) => {
        return {
          ...result,
          data: this.mapToCategory(result.data),
        };
      })
    );
  }

  reorderCategories(body: {
    parentId?: number | null;
    categories: Pick<RawCategory, 'id' | 'order'>[];
  }) {
    return this.http.patch<Result<RawCategory[]>>(
      `${this.BASE_URL}/reorder`,
      body
    );
  }

  categories(keyword?: string): Observable<Result<Category[]>> {
    return this.http
      .get<Result<RawCategory[]>>(`${this.BASE_URL}`, {
        params: keyword ? { keyword: keyword } : {},
      })
      .pipe(
        map((result) => ({
          ...result,
          data: result.data.map(this.mapToCategory.bind(this)),
        }))
      );
  }
  categoriesByParentId(parentId: number): Observable<Result<Category[]>> {
    return this.http
      .get<Result<RawCategory[]>>(`${this.BASE_URL}/${parentId}/children`)
      .pipe(
        map((response) => ({
          ...response,
          data: response.data.map((category) => this.mapToCategory(category)),
        }))
      );
  }

  addCategory(body: AddCategoryBody): Observable<Result<Category>> {
    // todo refactor
    const form = new FormData();
    form.append('approved', body.approved ? '1' : '0');
    form.append('paid', body.paid ? '1' : '0');
    form.append('name', body.name);
    form.append('type', body.type);
    if (body.description) {
      form.append('description', body.description);
    }
    if (body.image) {
      form.append('image', body.image);
    }
    if (body.parent_id) {
      form.append('parent_id', body.parent_id.toString());
    }

    return this.http.post<Result<RawCategory>>(`${this.BASE_URL}`, form).pipe(
      map((result) => {
        return {
          ...result,
          data: this.mapToCategory(result.data),
        };
      })
    );
  }

  updateCategory(
    id: number,
    body: UpdateCategoryBody
  ): Observable<Result<Category>> {
    // todo refactor
    const form = new FormData();
    if (body.approved != null) {
      form.append('approved', body.approved ? 'true' : 'false');
    }
    if (body.paid != null) {
      form.append('paid', body.paid ? 'true' : 'false');
    }
    if (body.name) {
      form.append('name', body.name);
    }
    if (body.description) {
      form.append('description', body.description);
    }
    if (body.image) {
      form.append('image', body.image);
    }
    if (body.type) {
      form.append('type', body.type);
    }

    return this.http
      .patch<Result<RawCategory>>(`${this.BASE_URL}/${id}`, form)
      .pipe(
        map((result) => {
          return {
            ...result,
            data: this.mapToCategory(result.data),
          };
        })
      );
  }

  deleteCategory(id: number): Observable<Result<never>> {
    return this.http.delete<Result<never>>(`${this.BASE_URL}/${id}`);
  }

  questions(
    id: number,
    pagination?: { page: number }
  ): Observable<PaginatedResult<Question[]>> {
    return this.http
      .get<PaginatedResult<RawQuestion[]>>(`${this.BASE_URL}/${id}/questions`, {
        params: { ...pagination },
      })
      .pipe(
        map((response) => {
          return {
            ...response,
            data: response.data.map((rawQuestion) => toQuestion(rawQuestion)),
          };
        })
      );
  }

  addQuestion(
    id: number,
    questionId: number,
    order: number
  ): Observable<Result<Question[]>> {
    return this.http
      .post<Result<RawQuestion[]>>(`${this.BASE_URL}/${id}/question`, {
        questionId: questionId,
        order: order,
      })
      .pipe(
        map((result) => ({
          ...result,
          data: result.data.map((question) => toQuestion(question)),
        }))
      );
  }

  removeQuestion(id: number, questionId: number): Observable<Result<unknown>> {
    return this.http.delete<Result<unknown>>(
      `${this.BASE_URL}/${id}/question?questionId=${questionId}`
    );
  }

  bulkInsertQuestions(
    id: number,
    body: BulkInsertQuestionBody
  ): Observable<Result<any>> {
    return this.http.post<Result<any>>(
      `${this.BASE_URL}/${id}/bulk-insert-questions`,
      body
    );
  }

  bulkInsertGoldenPoints(
    body: BulkInsertGoldenPointsBody
  ): Observable<Result<Category[]>> {
    return this.http
      .post<Result<RawCategory[]>>(`${this.BASE_URL}/bulk-insert`, body)
      .pipe(
        map((response) => ({
          ...response,
          data: response.data.map((raw) => this.mapToCategory(raw)),
        }))
      );
  }

  private mapToCategory(raw: RawCategory): Category {
    return {
      ...raw,
      parent_id: raw.parent_id ? +raw.parent_id : raw.parent_id,
      approved: !!raw.approved,
      paid: !!raw.paid,
      order: raw.order,
      breadcrumbs: raw.breadcrumbs,
    };
  }
}
